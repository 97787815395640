<template>
    <v-card v-if="isEnabled" :loading="processed" class="event-destination-wrapper">
        <v-toolbar dark dense>
            <v-toolbar-title class="pr-2">
                <small>Pozor upravujete místo dojezdu</small>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn color="secondary" @click="locate">
                    zobrazit
                </v-btn>
                <v-btn icon small @click="end">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
    </v-card>
</template>
<script>
    import {mapActions, mapGetters, mapState} from "vuex";

    export default {
        name: 'map-card-event-destination',
        computed: {
            ...mapState({
                processed: state => state.eventDestination.processed,
                coordinates: state => state.eventDestination.coordinates,
            }),
            ...mapGetters('eventDestination', {
                isEnabled: 'isEnabled',
            }),
        },
        methods: {
            locate() {
                this.setAutoFocus(false);
                this.extendTo(this.coordinates);
            },
            ...mapActions('eventDestination', {
                end: 'end',
            }),
            ...mapActions('map', {
                extendTo: 'extendTo',
                setAutoFocus: 'setAutoFocus',
            }),
        },
    }
</script>
